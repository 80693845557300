import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'HH:mm') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'd LLL y, HH:mm');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace('less than a minute ago', 'now')
        .replace(' minute ago', 'm')
        .replace(' minutes ago', 'm')
        .replace('a minute ago', 'm')
        .replace('an hour ago', 'h')
        .replace(' hour ago', 'h')
        .replace(' hours ago', 'h')
        .replace(' day ago', 'd')
        .replace('a day ago', 'd')
        .replace(' days ago', 'd')
        .replace('a month ago', 'mo')
        .replace(' months ago', 'mo')
        .replace(' month ago', 'mo')
        .replace('a year ago', 'y')
        .replace(' year ago', 'y')
        .replace(' years ago', 'y')
        .replace(/cerca de|mais de|quase|há/g, '')
        .replace('menos de um minuto atrás', 'agora')
        .replace('menos de um minuto', 'agora')
        .replace(' minuto atrás', 'm')
        .replace(' minutos atrás', 'm')
        .replace('um minuto atrás', 'm')
        .replace('uma hora atrás', 'h')
        .replace(' hora atrás', 'h')
        .replace(' horas atrás', 'h')
        .replace(' dia atrás', 'd')
        .replace('um dia atrás', 'd')
        .replace(' dias atrás', 'd')
        .replace('um mês atrás', 'mês')
        .replace(' meses atrás', 'mês')
        .replace(' mês atrás', 'mês')
        .replace('um ano atrás', 'a')
        .replace(' ano atrás', 'a')
        .replace(' anos atrás', 'a')
        .replace('minutos', 'min')
        .replace('segundos', 'seg');

      return convertToShortTime;
    },
  },
};
