export const CONVERSATION_EVENTS = Object.freeze({
  EXECUTED_A_MACRO: 'Executou uma macro',
  SENT_MESSAGE: 'Enviou uma mensagem',
  SENT_PRIVATE_NOTE: 'Enviou uma mensagem privada',
  INSERTED_A_CANNED_RESPONSE: 'Inseriu uma resposta enlatada',
  TRANSLATE_A_MESSAGE: 'Traduziu uma mensagem',
  INSERTED_A_VARIABLE: 'Inseriu uma variável',
  USED_MENTIONS: 'Menções usadas',
  SEARCH_CONVERSATION: 'Pesquisou conversas',
  APPLY_FILTER: 'Aplicou Filtro na lista de conversas',
  CHANGE_PRIORITY: 'Prioridade atribuída a uma conversa',
  INSERT_ARTICLE_LINK: 'Artigo inserido em resposta via pesquisa de artigo',
});

export const ACCOUNT_EVENTS = Object.freeze({
  ADDED_TO_CANNED_RESPONSE: 'Utilizada opção de resposta com template',
  ADDED_A_CUSTOM_ATTRIBUTE: 'Adicionado um atributo personalizado',
  ADDED_AN_INBOX: 'Adicionou um canal de atendimento',
  OPEN_MESSAGE_CONTEXT_MENU: 'Menu de contexto de mensagem aberta',
  OPENED_NOTIFICATIONS: 'Notificações abertas',
  MARK_AS_READ_NOTIFICATIONS: 'Notificações marcadas como lida',
  OPEN_CONVERSATION_VIA_NOTIFICATION: 'Conversa aberta via notificação',
});

export const LABEL_EVENTS = Object.freeze({
  CREATE: 'Criou um marcador',
  UPDATE: 'Atualizou um marcador',
  DELETED: 'Excluiu um marcador',
  APPLY_LABEL: 'Aplicou um marcador',
});

// REPORTS EVENTS
export const REPORTS_EVENTS = Object.freeze({
  DOWNLOAD_REPORT: 'Baixou um relatório',
  FILTER_REPORT: 'Usou filtros nos relatórios',
});

// CONTACTS PAGE EVENTS
export const CONTACTS_EVENTS = Object.freeze({
  APPLY_FILTER: 'Filtros aplicados na lista de contatos',
  SAVE_FILTER: 'Salvou um filtro na lista de contatos',
  DELETE_FILTER: 'Excluiu um filtro na lista de contatos',

  APPLY_SORT: 'Lista de contatos re-ordernada',
  SEARCH: 'Lista de contatos pesquisados',
  CREATE_CONTACT: 'Criou um contato',
  MERGED_CONTACTS: 'Opção de contato de mesclagem usada',
  IMPORT_MODAL_OPEN: 'Contatos de importação abertos modal',
  IMPORT_FAILURE: 'Os contatos de importação falharam',
  IMPORT_SUCCESS: 'Contatos importados com sucesso',
});

// CAMPAIGN EVENTS
export const CAMPAIGNS_EVENTS = Object.freeze({
  OPEN_NEW_CAMPAIGN_MODAL: 'Abriu um novo modal de campanha',
  CREATE_CAMPAIGN: 'Criou uma nova campanha',
  UPDATE_CAMPAIGN: 'Atualizou uma campanha',
  DELETE_CAMPAIGN: 'Excluiu uma campanha',
});

// PORTAL EVENTS
export const PORTALS_EVENTS = Object.freeze({
  ONBOARD_BASIC_INFORMATION: 'Novo portal: informações básicas concluídas ',
  ONBOARD_CUSTOMIZATION: 'Novo portal: personalização concluída ',
  CREATE_PORTAL: 'Criou um portal ',
  DELETE_PORTAL: 'Excluiu um portal ',
  UPDATE_PORTAL: 'Atualizado um portal ',

  CREATE_LOCALE: 'Criou uma nova localidade',
  SET_DEFAULT_LOCALE: 'Definiu localidade padrão do portal ',
  DELETE_LOCALE: 'Excluiu uma localidade do portal ',
  SWITCH_LOCALE: 'Localidade do portal alterada ',

  CREATE_CATEGORY: 'Criou uma categoria de portal ',
  DELETE_CATEGORY: 'Excluiu uma categoria de portal ',
  EDIT_CATEGORY: 'Editou uma categoria de portal ',

  CREATE_ARTICLE: 'Criou um artigo ',
  PUBLISH_ARTICLE: 'Publicou um artigo ',
  ARCHIVE_ARTICLE: 'Arquivado um artigo ',
  DELETE_ARTICLE: 'Excluiu um artigo ',
  PREVIEW_ARTICLE: 'Artigo visualizado ',
});

export const OPEN_AI_EVENTS = Object.freeze({
  SUMMARIZE: "OpenAI: Usado 'Resumo' ",
  REPLY_SUGGESTION: 'Openai: sugestão de resposta usada ',
  REPHRASE: "OpenAI: Usado 'Refrase' ",
  FIX_SPELLING_AND_GRAMMAR: "OpenAI: usado 'Consertar Ortografia e Gramática'",
  SHORTEN: "OpenAI: Usado 'Encurtar'",
  EXPAND: "OpenAI: Usado 'Expandir'",
  MAKE_FRIENDLY: "OpenAI: usado 'Tom Amigavél'",
  MAKE_FORMAL: "OpenAI: usado 'Tom Formal'",
  SIMPLIFY: "OpenAI: usado 'simplificar'",
  APPLY_LABEL_SUGGESTION: 'OpenAI: Aplicou marcador via sugestão',
  DISMISS_LABEL_SUGGESTION: 'OpenAI: Dispensada sugestão de marcador',
  ADDED_AI_INTEGRATION_VIA_CTA_BUTTON:
    'OpenAI: Adicionado integração de IA via botão CTA',
  DISMISS_AI_SUGGESTION: 'OpenAI: descartou sugestões da IA',
});

export const GENERAL_EVENTS = Object.freeze({
  COMMAND_BAR: "Usado a 'barra de comando'",
});
