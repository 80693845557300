<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{
            $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.LABEL')
          }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.PLACEHOLDER'
              )
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
        <label :class="{ error: $v.phoneNumber.$error }">
          {{
            $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.LABEL')
          }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.PLACEHOLDER'
              )
            "
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">{{
            $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.ERROR')
          }}</span>
        </label>

        <label :class="{ error: active }">
          <button
            type="button"
            class="button clear primary"
            :disabled="
              autoGenQRCODE ||
              uiFlags.isCreatingQRCODE ||
              isInitializingQRCODE ||
              $v.phoneNumber.$error ||
              !phoneNumber ||
              !channelName
            "
            @click="getQRCode()"
          >
            {{
              isInitializingQRCODE
                ? $t(
                    'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.QRCODE_BUTTON_GENERATING'
                  )
                : $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.QRCODE_BUTTON')
            }}
          </button>
        </label>
        <br />
        <div
          v-if="!active"
          :class="`h-[300px] w-[300px] flex justify-center items-center ${
            !qrCode && 'bg-slate-100'
          }`"
        >
          <spinner
            v-if="isInitializingQRCODE"
            class="large"
            color-scheme="primary"
          />
          <qrcode-vue
            v-if="qrCode && !isInitializingQRCODE"
            :value="qrCode"
            :size="300"
            level="H"
          />
        </div>
        <br />
      </div>
      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="
            $t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.SUBMIT_BUTTON')
          "
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import QrcodeVue from 'qrcode.vue';
import Spinner from 'shared/components/Spinner.vue';

const mustBeActive = activeValue => {
  return activeValue === true;
};

export default {
  components: {
    PageHeader,
    QrcodeVue,
    Spinner,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      phoneNumber: '',
      active: null,
      qrCode: '',
      autoGenQRCODE: false,
      timerId: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    isInitializingQRCODE() {
      return this.active === false && this.qrCode === '';
    },
  },
  validations: {
    channelName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
    active: {
      mustBeActive,
    },
  },
  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const whatsappUnofficialChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              phone_number: this.phoneNumber,
              type: 'whatsapp_unofficial',
            },
          }
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappUnofficialChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.API.ERROR_MESSAGE'
          )
        );
      }
    },
    async getQRCode() {
      try {
        const response = await this.$store.dispatch('inboxes/getQRCode', {
          phoneNumber: this.phoneNumber,
        });

        this.qrCode = response.qrCode ? response.qrCode : '';

        this.active = response.active;

        if (response.active) {
          this.showAlert(
            this.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.ACTIVE.ALERT')
          );
        }
        if (!response.active) {
          this.timerId = setTimeout(() => this.getQRCode(), 1500);
          this.autoGenQRCODE = true;
        }
      } catch (error) {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.API.ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>
