var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-body small-9 columns"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.TITLE'),"header-content":_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.DESC')}}),_vm._v(" "),_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.createChannel()}}},[_c('div',{staticClass:"medium-8 columns"},[_c('label',{class:{ error: _vm.$v.channelName.$error }},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.LABEL'))+"\n        "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelName),expression:"channelName",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.PLACEHOLDER'
            )},domProps:{"value":(_vm.channelName)},on:{"blur":[_vm.$v.channelName.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.channelName=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.channelName.$error)?_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.CHANNEL_NAME.ERROR')))]):_vm._e()]),_vm._v(" "),_c('label',{class:{ error: _vm.$v.phoneNumber.$error }},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.LABEL'))+"\n        "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phoneNumber),expression:"phoneNumber",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.PLACEHOLDER'
            )},domProps:{"value":(_vm.phoneNumber)},on:{"blur":[_vm.$v.phoneNumber.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.phoneNumber.$error)?_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.PHONE_NUMBER.ERROR')))]):_vm._e()]),_vm._v(" "),_c('label',{class:{ error: _vm.active }},[_c('button',{staticClass:"button clear primary",attrs:{"type":"button","disabled":_vm.autoGenQRCODE ||
            _vm.uiFlags.isCreatingQRCODE ||
            _vm.isInitializingQRCODE ||
            _vm.$v.phoneNumber.$error ||
            !_vm.phoneNumber ||
            !_vm.channelName},on:{"click":function($event){return _vm.getQRCode()}}},[_vm._v("\n          "+_vm._s(_vm.isInitializingQRCODE
              ? _vm.$t(
                  'INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.QRCODE_BUTTON_GENERATING'
                )
              : _vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.QRCODE_BUTTON'))+"\n        ")])]),_vm._v(" "),_c('br'),_vm._v(" "),(!_vm.active)?_c('div',{class:`h-[300px] w-[300px] flex justify-center items-center ${
          !_vm.qrCode && 'bg-slate-100'
        }`},[(_vm.isInitializingQRCODE)?_c('spinner',{staticClass:"large",attrs:{"color-scheme":"primary"}}):_vm._e(),_vm._v(" "),(_vm.qrCode && !_vm.isInitializingQRCODE)?_c('qrcode-vue',{attrs:{"value":_vm.qrCode,"size":300,"level":"H"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('br')]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('woot-submit-button',{attrs:{"loading":_vm.uiFlags.isCreating,"button-text":_vm.$t('INBOX_MGMT.ADD.WHATSAPP_UNOFFICIAL_CHANNEL.SUBMIT_BUTTON')}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }