/* global axios */

class WhatsAppUnofficialChannel {
  // eslint-disable-next-line no-empty-function
  constructor() {}

  // eslint-disable-next-line class-methods-use-this
  get url() {
    return '/whatsappunofficial';
  }

  async getQRCode(params) {
    const response = await axios.get(
      `${this.url}/${params.phoneNumber}/qrcode`
    );

    return response;
  }

  async reloadSession(params) {
    const response = await axios.post(
      `${this.url}/${params.phoneNumber}/reload`
    );

    return response;
  }
}

export default new WhatsAppUnofficialChannel();
